/* glue: 0.13 hash: 2206a35e3c */

@sprites_height: 387px;
@sprites_width: 533px;


.sprite-companies-logos {
  width: 363px;
  height: 30px;
  background-position: -5px -5px;
}

.sprite-logo-big {
  width: 202px;
  height: 35px;
  background-position: -5px -45px;
}

.sprite-admin-big-logo {
  width: 173px;
  height: 173px;
  background-position: -5px -90px;
}

.sprite-admin-logo {
  width: 164px;
  height: 60px;
  background-position: -188px -90px;
}

.sprite-unauthorized {
  width: 150px;
  height: 150px;
  background-position: -378px -5px;
}

.sprite-blankslate {
  width: 136px;
  height: 109px;
  background-position: -5px -273px;
}

.sprite-logo {
  width: 126px;
  height: 22px;
  background-position: -378px -165px;
}

.sprite-logo-backoffice {
  width: 124px;
  height: 35px;
  background-position: -378px -197px;
}

.sprite-request-icon {
  width: 40px;
  height: 40px;
  background-position: -188px -160px;
}

.sprite-google-icon {
  width: 40px;
  height: 40px;
  background-position: -238px -160px;
}

.sprite-document-icon {
  width: 40px;
  height: 40px;
  background-position: -288px -160px;
}

.sprite-angle-down-active {
  width: 27px;
  height: 23px;
  background-position: -217px -45px;
}

.sprite-history-icon-person {
  width: 26px;
  height: 26px;
  background-position: -338px -160px;
}

.sprite-history-icon-pencil {
  width: 26px;
  height: 26px;
  background-position: -188px -210px;
}

.sprite-history-icon-finish {
  width: 26px;
  height: 26px;
  background-position: -224px -210px;
}

.sprite-history-icon-clock {
  width: 26px;
  height: 26px;
  background-position: -260px -210px;
}

.sprite-history-icon-check {
  width: 26px;
  height: 26px;
  background-position: -296px -210px;
}

.sprite-angle-down {
  width: 25px;
  height: 23px;
  background-position: -254px -45px;
}

.sprite-arrow-left {
  width: 23px;
  height: 17px;
  background-position: -378px -242px;
}

.sprite-icon-question {
  width: 20px;
  height: 20px;
  background-position: -289px -45px;
}

.sprite-reject-icon {
  width: 14px;
  height: 16px;
  background-position: -514px -165px;
}

.sprite-icon-sign {
  width: 16px;
  height: 16px;
  background-position: -512px -197px;
}

.sprite-icon-reload {
  width: 16px;
  height: 16px;
  background-position: -411px -242px;
}

.sprite-icon-export {
  width: 16px;
  height: 16px;
  background-position: -437px -242px;
}

.sprite-icon-download {
  width: 13px;
  height: 16px;
  background-position: -463px -242px;
}

.sprite-icon-action {
  width: 16px;
  height: 15px;
  background-position: -486px -242px;
}

.sprite-approve-icon {
  width: 14px;
  height: 16px;
  background-position: -319px -45px;
}

.sprite-chevron-right {
  width: 15px;
  height: 15px;
  background-position: -512px -242px;
}

.sprite-chevron-down {
  width: 15px;
  height: 15px;
  background-position: -343px -45px;
}

.sprite-admin-icon-import {
  width: 15px;
  height: 13px;
  background-position: -332px -210px;
}

.sprite-admin-icon-import-active {
  width: 15px;
  height: 13px;
  background-position: -188px -246px;
}

.sprite-admin-icon-impersonate {
  width: 15px;
  height: 15px;
  background-position: -151px -273px;
}

.sprite-admin-icon-impersonate-active {
  width: 15px;
  height: 15px;
  background-position: -176px -273px;
}

.sprite-check-white {
  width: 14px;
  height: 11px;
  background-position: -213px -246px;
}

.sprite-admin-icon-edit {
  width: 14px;
  height: 14px;
  background-position: -201px -273px;
}

.sprite-admin-icon-edit-active {
  width: 14px;
  height: 14px;
  background-position: -225px -273px;
}

.sprite-check-blue {
  width: 12px;
  height: 10px;
  background-position: -237px -246px;
}

.sprite-close-cross {
  width: 10px;
  height: 10px;
  background-position: -357px -210px;
}

.sprite-arrow-up {
  width: 8px;
  height: 8px;
  background-position: -512px -223px;
}

.sprite-arrow-up-active {
  width: 8px;
  height: 8px;
  background-position: -259px -246px;
}

.sprite-arrow-down {
  width: 8px;
  height: 8px;
  background-position: -277px -246px;
}

.sprite-arrow-down-active {
  width: 8px;
  height: 8px;
  background-position: -295px -246px;
}


.sprite {
  display: inline-block;
  background-size: @sprites_width @sprites_height;
  background-image: url(../../images/sprites/sprites@1x.png);

  @media
  (min-device-pixel-ratio: 2.0),
  (-webkit-min-device-pixel-ratio: 2.0),
  (min--moz-device-pixel-ratio: 2.0),
  (min-resolution: 2.0dppx) {
    display: inline-block;
    background-size: @sprites_width @sprites_height;
    background-image: url(../../images/sprites/sprites@2x.png);
  }
}