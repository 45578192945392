@import "@jobandtalent/design-system/dist/assets/less/organisms";
@import "@jobandtalent/design-system/dist/assets/less/molecules";
@import "@jobandtalent/design-system/dist/assets/less/atoms";
@import "@jobandtalent/design-system/dist/styles";
@import "@jobandtalent/design-system/dist/assets/v2/less/fonts";

@bg_grey: #F6F6F8;

a {
  color: @primary_blue;
  text-decoration: none;
}

a:visited, a:active {
  text-decoration: none;
}

.workforce-external-link::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 2px 0 2px;
}

body {
  .ds-text-body-base-regular();
  color: @dark;
  background-color: @white;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.for-admin {
    background-color: @bg_grey;
    overflow-x: auto;
  }

  &.with-modal {
    overflow: hidden;
  }
}

html {
  box-sizing: border-box;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.react-app-container {
  flex: auto;
}

.react-error-container {
  z-index: 1001;
}

.backoffice-navigation {
  display: flex;
  width: 250px;
  height: 100%;

  &.collapsed {
    width: 60px;
  }
}

.app-wrapper {
  display: flex;
  height: 100vh;
}

.app-content {
  flex: 1;
  overflow: auto;
}

.main-wrapper {
  .clearfix();
  position: relative;
  padding: 0 20px;
  margin: 0 auto;
  flex: 1;
  z-index: 6;

  &.with-open-filters {
    opacity: .1;
    position: none;
  }

  &.big-padding {
    padding: 0 40px;
  }
}

.workforce_container {
  position: relative;
  background: @white;
  border: 1px solid @black_alpha10;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 @black_alpha05;
}

.workforce_client_container {
  .workforce_container();
  margin: 40px;

  &.not-padded {
    margin: 40px 0;
  }
}

.main-error {
  z-index: 999 !important;

  &.full-width {
    width: auto;
  }
}

.ds-internal-btn-primary-base {
  &:disabled {
    background-color: @primary_blue_alpha40;
  }
}

button {
  color: @primary_blue;
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  outline: none;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
     -moz-user-select: none;
      -ms-user-select: none;

      &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
      }
}

.workforce-unauthorized-icon {
  font-size: 164px;
  color: @red;
  width: 120px;
  height: 120px;
  border: 10px solid @red;
  display: inline-block;
  line-height: 106px;
  margin-bottom: 28px;
  border-radius: 120px;
  box-shadow: 0 4px 15px @black_alpha20;
}

.workforce-big-row-icon {
  vertical-align: middle;
  margin-right: 15px;
}

.workforce-breadcrumb {
  .clearfix();

  position: relative;
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid @dark_alpha10;
  margin-bottom: 35px;

  a {
    text-decoration: none;
  }
}

.workforce-breadcrumb-chevron {
  margin: 0 8px;
  vertical-align: middle;
}

.workforce_option_picker {
  .ds-mol-option-picker-closed();
  .ds-mol-option-picker();
}

.ds-mol-dropdown.workforce-option-picker-content {
  display: none;

  &:hover {
    display: block;
  }
}

.workforce-option-picker-trigger:hover + .workforce-option-picker-content {
  display: block;
}

.workforce-logs-icon.icon {
  color: @dark_alpha80;
  height: 9px;
  line-height: 1.6;
  margin: 0 (10 / @px);
  transform: rotate(180deg);
  width: 9px;
}

.workforce-logs-chips-container {
  display: inline-block;
}

.workforce-logs-chips-base {
  background-color: @primary_blue_alpha08;
  border-radius: 2px;
  display: inline-block;
  font-size: (10 / @px);
  font-weight: 600;
  line-height: 1.6;
  padding: (2 / @px) (10 / @px) 0;
  vertical-align: middle;

  &.previous {
    background-color: @red_alpha10;
  }

  &.next {
    background-color: @green_alpha08;
  }
}

.workforce-editable-input {
  display: inline-block;
  min-height: 40px;
}

.workforce-wizard {
  height: 100%;
  display: flex;
  flex-direction: column;

  .DayPickerInput {
    width: 100%;
  }
}

.workforce-wizard-navigation {
  width: 80%;
  padding: 50px 0 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.workforce-wizard-navigation-step {
  text-align: center;
  position: relative;
  width: 100%;
  color: @dark_alpha60;
  cursor: initial;
  align-self: flex-start;

  &:after {
    content: "";
    background: @black_alpha10;
    height: 2px;
    position: absolute;
    top: 10px;
    width: calc(100% - 40px);
    left: calc(50% + 20px);
  }

  &:last-child:after {
    display: none;
  }

  &.active {
    color: @primary_blue;
  }

  &.done {
    color: @green;
  }

  &.clickable {
    cursor: pointer;
  }
}

.workforce-wizard-navigation-step-bullet {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: @black_alpha10;
  color: @dark_alpha60;
  line-height: 22px;
  display: inline-block;
  font-size: 10px;
  margin-bottom: 15px;
  vertical-align: middle;

  &.active {
    position: relative;
    background-color: @primary_blue_alpha40;

    &:after {
      content: "";
      border-radius: 100%;
      display: block;
      width: 8px;
      height: 8px;
      background-color: @primary_blue;
      position: absolute;
      left: 7px;
      top: 7px;
    }
  }
}

svg.workforce-wizard-navigation-step-tick {
  color: @green;
  width: 14px;
  height: 22px;
  margin-bottom: 15px;
  vertical-align: middle;
}

.workforce-wizard-step {
  padding: 0 50px 100px;
  min-height: 0;
  overflow: auto;

  &.without-navigation {
    padding-top: 50px;
  }

  .workforce_loading_wrapper {
    position: relative;
    margin-top: 40px;
    bottom: auto;
    top: auto;
  }
}

.workforce-wizard-step-number {
  .ds-text-footnote-regular();

  color: @dark_alpha40;
}

.workforce-wizard-step-header {
  .ds-text-display-thin();

  font-size: 40px;
}

.workforce-wizard-step-subheader {
  .ds-text-body-big-regular();

  color: @dark_alpha60;
}

.workforce-wizard-step-small-header {
  .ds-text-subtitle-base-regular();

  margin: 30px 0 20px;
}

.workforce-wizard-selector {
  border-radius: 4px;
  border: 1px solid @black_alpha10;
  margin-bottom: 20px;
  position: relative;

  .hoverable {
    display: none;
  }

  &.for-new {
    border-style: dashed;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 2px 8px 0 @black_alpha10;

    .hoverable {
      display: inline-block;
    }
  }

  &.selected {
    border-color: @primary_blue;
  }

  &:focus {
    outline: none;
    border-color: @primary_blue_alpha40;
  }

  &.with-margin {
    margin-top: 30px;
  }

  &.opened:before {
    content: '';
    position: absolute;
    width: 1px;
    top: 55px;
    left: 33px;
    bottom: 35px;
    background-color: #E5E5E5; // #E5E5E5 is the solid version of @black_alpha10
  }
}

.workforce-wizard-selector-content-wrapper {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  outline: none;

  &.non-selectable {
    cursor: initial;
  }
}

.workforce-wizard-selector-icon-wrapper {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 20px;

  svg {
    width: 13px;
    height: 13px;
  }

  &.plus {
    background-color: @primary_blue_alpha08;

    svg {
      transform: rotate(45deg);
      color: @primary_blue;
    }
  }

  &.band_aid {
    background-color: @yellow_alpha08;

    svg {
      color: @yellow;
    }
  }

  &.heart {
    background-color: @red_alpha10;

    svg {
      color: @red
    }
  }
}

.workforce-wizard-selector-text {
  width: 50%;
}

.workforce-wizard-selector-title {
  .ds-text-body-big-semibold();

  margin-bottom: 5px;

  span {
    .ds-text-footnote-regular();

    display: block;
    color: @dark_alpha60;
    margin-top: 5px;
  }
}

.workforce-wizard-selector-subtitle {
  .ds-text-footnote-regular();

  color: @dark_alpha60;
}

.workforce-wizard-selector-chip {
  margin-left: 10px;
  vertical-align: text-top;
}

.workforce-wizard-selector-actions {
  margin-left: auto;
  white-space: nowrap;
}

.workforce-wizard-selector-action {
  color: @primary_blue;
  padding-left: 20px;

  &:before {
    content: "";
    display: inline-block;
    height: 35px;
    width: 1px;
    background-color: @black_alpha05;
    vertical-align: middle;
    margin-right: 20px;
  }

  &:first-child:before {
    content: none;
  }
}

svg.workforce-wizard-selector-chevron {
  color: @primary_blue;
  width: 10px;
  height: 12px;
  margin-left: 7px;

  &.opened {
    transform: rotate(180deg);
    vertical-align: middle;
  }
}

.workforce-wizard-selector-details {
  padding: 0 20px 20px 6px;
  margin-left: 35px;
}

.workforce-wizard-selector-details-label {
  .ds-text-footnote-regular();

  color: @dark_alpha60;
  display: block;
  margin: 20px 0 5px;

  &:first-child {
    margin-top: 0;
  }
}

.workforce-wizard-selector-details-link {
  .ds-text-body-base-semibold();

  position: absolute;
  right: 30px;
  top: 40px;

  .icon {
    color: @dark_alpha60;
    margin-right: 10px;
    vertical-align: middle;
    width: 15px;
    height: 15px;
  }
}

.workforce-wizard-selector-details-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 2px 8px 0 @black_alpha10;
  border-radius: 4px;
  border-left: 3px solid @primary_blue;
  padding: 20px;
  font-size: 15px;
  line-height: 16px;
  background-color: @white;
  margin: 0 30px 30px 14px;

  h4 {
    margin-bottom: 10px;
    color: @dark_alpha60;
  }

  .icon {
    color: @primary_blue;
    transform: rotate(180deg);
    margin: 0 20px;
    width: 24px;
  }
}

.workforce-wizard-selector-details-card-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  color: @dark_alpha60;

  .icon {
    color: @dark_alpha60;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin: 0 5px;
  }
}

.workforce-wizard-selector-log-entry {
  margin-bottom: 10px;
  border-radius: 3px;
  padding: 15px 5px 15px;
  border: 1px dashed transparent;
  position: relative;

  &.opened {
    border-color: @primary_blue;
  }

  &:last-child {
    margin-bottom: 0;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      top: 18px;
      left: -9px;
      bottom: 0;
      background: linear-gradient(to bottom, #E5E5E5 0px, @white 2px) // #E5E5E5 is the solid version of @black_alpha10
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 9px;
    left: -9px;
    border-radius: 100%;
    border: 1px solid;
    border-color: transparent transparent #E5E5E5 transparent; // #E5E5E5 is the solid version of @black_alpha10
    transform: rotate(45deg);
  }
}

.workforce-wizard-selector-log-entry-header {
  .ds-text-footnote-regular();

  position: relative;
  color: @dark;
  display: flex;


  h4 {
    .ds-text-body-base-semibold();

    margin-bottom: 5px;
  }

  &:hover {
    cursor: pointer;

    .workforce-wizard-selector-log-entry-chevron {
      color: @dark;
    }
  }

  &:focus {
    outline: none;
  }
}

.workforce-wizard-selector-log-entry-icon-wrapper {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: @primary_blue_alpha08;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
      width: 15px;
      height: 15px;
  }
}

.workforce-wizard-selector-log-entry-icon {
  color: @primary_blue;
  width: 8px;
  height: 8px;

  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
      width: 7.5px;
      height: 7.5px;
  }
}

.workforce-wizard-selector-log-entry-chevron {
  width: 15px;
  height: 15px;
  color: @dark_alpha60;
  align-self: center;
  margin-left: auto;
  margin-right: 25px;

  &.opened {
    transform: rotate(180deg);
  }
}

.workforce-wizard-selector-log-entry-details {
  position: relative;
  padding: 20px 0 0 25px;
}

.workforce-wizard-switch-label {
  display: block;
  margin-bottom: 10px;
}

.workforce-wizard-summary-label {
  font-weight: 600;
  display: block;
  margin-bottom: 15px;

  &.inline {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    margin-bottom: 0;

    & + .icon {
      margin-bottom: 0;
    }
  }
}

.workforce-wizard-selector-log-entry-expand {
  position: relative;
  margin-left: 8px;
  margin-bottom: 15px;

  .icon {
    width: 15px;
    height: 15px;
    vertical-align: sub;
    margin-right: 5px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 3px;
    top: 3px;
    left: -17px;
    border: solid @white;
    border-width: 3px 0 3px 0;
    height: 10px;
  }
}

.workforce-wizard-step-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: @white;
  border-top: 1px solid @black_alpha10;
  box-shadow: 0 -2px 8px @black_alpha05;
  padding: (20 / @px);

  button {
    &.right {
      float: right;
      margin-left: 10px;
    }

    &.full {
      width: 100%;
    }
  }
}

.workforce-wizard-action-selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.workforce-wizard-action-selector {
  background: @white;
  border: 1px solid @black_alpha10;
  border-radius: 4px;
  font-size: 16px;
  color: @dark;
  height: 46px;
  text-align: center;
  width: calc(50% - 5px);

  .icon {
    color: @primary_blue;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 2px;
  }

  .workforce-wizard-action-fitnote-icon {
    border-radius: 20px;
    background-color: @primary_blue;
    color: @white;
    padding-left: 3px;
  }

  &:hover,
  &.selected {
    background: @primary_blue_alpha08;
    border: 1px solid @primary_blue_alpha40;
    box-shadow: 0 2px 8px 0 @black_alpha10;

    .workforce-wizard-action-fitnote-icon {
      background-color: @primary_blue;
      color: #EDF7FF;
    }
  }
}

.workforce-input-error {
  margin-bottom: 10px;
  text-align: right;
  width: 100%;
}

.ds-mol-input-field .ds-atom-text-input-normal.error {
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
}

.copiable-span {
  color: inherit;

  .icon {
    display: none;
    width: 10px;
    height: 10px;
    vertical-align: middle;
    margin-bottom: 2px;
    margin-left: 5px;
    color: @primary_blue;
  }

  &:hover .icon {
    display: inline-block;
  }
}

.flash-messages-container {
  margin: 40px 40px 0;

  .ds-organisms-alert {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.error-snackbar-reload-button {
  color: @white_alpha80;
  font-weight: 600;
  margin-top: 10px;

  &:hover {
    color: @white;
  }
}

.filters-tags-wrapper {
  margin-top: 20px;
}

.date-picker-input.multiple-months .DayPicker-Months {
  flex-wrap: nowrap;
}

// FIXME: Delete this when the CSS imports are correctly organized
div.DayPicker {
  font-size: 14px;
}

.text-right {
  text-align: right;
}

.deleted-tag {
  font-size: 12px;
  text-transform: uppercase;
  color: @white;
  background: @red;
  padding: 2px 4px;
  font-weight: 600;
  vertical-align: middle;
  border-radius: 3px;
}

.bulk-selection-banner {
  display: flex;
  flex: 1;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px 0 0;
  border-radius: 5px;
  background-color: @bg_grey;

  .workforce-filter-menu-clear{
    margin: 0 5px;
  }

  &.inline {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    padding: 10px 20px;
    border: 1px solid @black_alpha10;
  }
}

.update-compensation-plan-modal {
  &.disabled .ds-org-confirm-modal-right-button {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}
