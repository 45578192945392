.workforce-authentication-sidebar {
  width: 460px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url(../../images/authentication_sidebar.jpg);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 500px;
}

.workforce-authentication-logo {
  .hide-text();
  margin: 50px;
}

.workforce-authentication-claim-wrapper {
  position: absolute;
  top: 50%;
  margin: -25% 50px 0;
}

.workforce-authentication-welcome {
  font-size: 12px;
  color: @white_alpha80;
  line-height: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 30px;
}

.workforce-authentication-claim {
  color: @white;
  font-size: 32px;
  line-height: 39px;
  margin: 0;
}

.workforce-authentication-trusted-by {
  position: absolute;
  bottom: 50px;
  left: 40px;
}

.workforce-authentication-trusted-by-text {
  .textSemibold();
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 20px;
  color: @white_alpha40;
}

.workforce-authentication-contact {
  position: absolute;
  top: 50px;
  right: 50px;
  color: @dark_alpha60;
  font-size: 10px;
  line-height: 12px;

  strong {
    .textSemibold();
  }
}

.workforce-authentication-contact-phone {
  .textSemibold();
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: @dark;

  &:hover {
    text-decoration: underline;
  }
}

.workforce-authentication-wrapper {
  padding: 50px 50px 50px 510px;
  display: table;
  height: 100%;
  width: 100%;
  min-height: 400px;
}

.workforce-authentication-content-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.workforce-authentication-content {
  text-align: left;
  display: inline-block;
  max-width: 540px;
  width: 100%;
  min-width: 300px;
}

.workforce-authentication-label {
  .textSemibold();
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 5px;
}

.workforce-authentication-input {
  .border-radius(3px);
  background: @white;
  border: 1px solid rgba(0, 0, 0, 0.10);
  box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.05);
  line-height: 50px;
  display: block;
  margin-bottom: 30px;
  width: 100%;
  height: 50px;
  padding: 0 10px;
}

.workforce-authentication-heading {
  .textBold();
  margin-bottom: 20px;
  font-size: 34px;
  line-height: 41px;
}

.workforce-authentication-explanation {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 30px;
  color: @dark_alpha80;
}

.workforce-authentication-link {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  text-decoration: underline;
  color: @dark_alpha60;

  &:hover {
    text-decoration: none;
  }
}

.workforce-authentication-button {
  .border-radius(5px);
  padding: 0 50px;
  color: @white;
  background: @primary_blue;
  background: -moz-linear-gradient(left, @primary_blue 0%, @primary_blue 51%, @primary_blue 100%);
  background: -webkit-linear-gradient(left, @primary_blue 0%, @primary_blue 51%, @primary_blue 100%);
  background: linear-gradient(to right, @primary_blue 0%,@primary_blue 51%, @primary_blue 100%);
  background-size: 200% auto;
  transition: background-position .2s;
  line-height: 50px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  margin-top: 50px;

  &:hover {
    background-position: right center;
  }
}

.workforce-authentication-back {
  display: inline-block;
  margin-bottom: 25px;
}

.workforce-authentication-errors {
  border-bottom: 3px solid @red;
  color: @red;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: @red_alpha10;

  li {
    line-height: 16px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
